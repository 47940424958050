import React from 'react';
import * as Styled from './StyledUserCard';
import BadgeIcon from 'common/IconComponents/BadgeIcon';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { selectCurrentUserProperty } from 'store/auth/authSelectors';

const GREEN = '#00FF00';
const DEFAULT_COLOR = '#D1D7FF66';

const VERIFIED = 'verified';
const VETTING_COMPLETE = 'VETTING_COMPLETE';

const UserCardBadges = () => {
  const currentUserId = useSelector(selectCurrentUserProperty('_id'));
  const currentProfileUserId = useSelector(profileSelectors.selectProfileProperty('userId'));
  const profleVettingStatus = useSelector(profileSelectors.selectProfileProperty('vettingStatus'));

  const viewMode = currentUserId !== currentProfileUserId;
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const isCompanyVerified = profleVettingStatus?.status === VETTING_COMPLETE;

  const profileStatus = useSelector(profileSelectors.selectProfileProperty('verificationStatus'));
  const currentUserStatus = useSelector(selectCurrentUserProperty('verificationStatus'));

  const isTalentVetted = useSelector(profileSelectors.selectProfileProperty('vetted'));

  const isTalentVerified = viewMode ? profileStatus === VERIFIED : currentUserStatus === VERIFIED;

  return (
    <Styled.UserCardBadgesWrapper>
      {isCompanyView ? (
        <BadgeIcon
          iconName="verified"
          tooltip={isCompanyVerified ? 'Business verified' : 'Verification pending'}
          tooltipId="company-info-tooltip"
          iconColor={isCompanyVerified ? null : DEFAULT_COLOR}
          iconSize={28}
        />
      ) : (
        <>
          <BadgeIcon
            iconName="personal_info"
            tooltip={isTalentVerified ? 'ID verified' : 'ID verification pending'}
            tooltipId="personal-info-tooltip"
            iconColor={isTalentVerified ? GREEN : DEFAULT_COLOR}
          />
          <BadgeIcon
            iconName="vetted-i"
            tooltip={isTalentVetted ? 'Vetted' : 'Vetting pending'}
            tooltipId="vetted-tooltip"
            iconColor={isTalentVetted ? GREEN : DEFAULT_COLOR}
          />
        </>
      )}
    </Styled.UserCardBadgesWrapper>
  );
};

export default UserCardBadges;
