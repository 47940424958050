import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import JobsHeader from '../commons/JobsHeader';
import RecommendedJobs from './RecommendedJobs';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { compose, pathOr, pluck } from 'ramda';
import { toSlug } from 'utils';

const Wrapper = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  @media ${pageSize.M} {
    margin-top: 12px;
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    margin-top: 16px;
  }
`;

const formatArrayToQuery = (data, paramName) => {
  const queryParameters = data?.map(discipline => `${paramName}=${encodeURIComponent(toSlug(discipline))}`);
  return queryParameters.join('&');
};

const AllJobs = () => {
  const [sortBy, setSortBy] = React.useState({
    label: 'Sort by Relevance',
    value: 'relevance'
  });
  const profileData = useSelector(profileSelectors.selectMyProfile);
  const disciplines = compose(pluck('name'), pathOr([], ['basicInfo', 'disciplines']))(profileData);
  const query = useMemo(() => formatArrayToQuery(disciplines, 'disciplines'), [disciplines]);

  const recommendedUrl = `${process.env.REACT_APP_NEXT_BASE_URL}/search/jobs?${query}&status=published`;

  return (
    <Wrapper>
      <Container>
        <JobsHeader header="all" mt={32} sortBy={sortBy} setSortBy={setSortBy} recommendedUrl={recommendedUrl} />
        <RecommendedJobs sortBy={sortBy.value} query={`${query}&status=published`} />
      </Container>
    </Wrapper>
  );
};

export default AllJobs;
