import styled from '@emotion/styled';
import withProfiles from 'hocs/withProfiles';
import Page404 from 'pages/service-page/Page404';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isLoggedInSelector } from 'store/selectors/currentUser';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import AccountSettings from './AccountSettings';
import ProfileSettings from './ProfileSettings';
import SettingsMenu from './SettingsMenu';

const PurpleGradient = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100vw;
  left: 0;
  top: -75vw;

  background: radial-gradient(50% 50% at 50% 50%, #6655cc 0%, rgba(74, 41, 204, 0) 100%);
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1240px;
  gap: 54px;
  margin: auto;
  @media ${pageSize.XS} {
    padding: 0 16px;
  }
  @media ${pageSize.S} {
    padding: 0 81px;
  }
  @media ${pageSize.M} {
    padding: 0 54px;
    gap: 45px;
  }
  @media ${pageSize.L} {
    padding: 0 58px;
  }
`;

const Title = styled.div`
  ${commonStyles.heading_1}
  padding: 80px 0;
`;

const FlexRowFlexColumn = styled.div`
  display: flex;
  flex-direction: row;

  @media ${pageSize.XS}, ${pageSize.S} {
    flex-direction: column;
  }
`;

const MainPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SettingsMenuWrapper = styled.div`
  @media ${pageSize.M} {
    margin-right: 54px;
  }
  @media ${pageSize.L} {
    margin-right: 58px;
  }
`;

const Settings = props => {
  const {
    match: { path }
  } = props;

  const isLoggedIn = useSelector(isLoggedInSelector);

  return (
    <>
      <PageWrapper>
        <PurpleGradient />
        <Title>Settings</Title>
        <FlexRowFlexColumn>
          <SettingsMenuWrapper>
            <SettingsMenu />
          </SettingsMenuWrapper>

          <MainPage>
            {isLoggedIn && (
              <Switch>
                <Redirect exact from={`${path}`} to={`${path}/profile`} />
                <Route exact path={`${path}/profile`} component={ProfileSettings} />
                <Route exact path={`${path}/account-settings`} component={props => <AccountSettings {...props} />} />
                {/* <Route exact path={`${path}/work-preferences`} component={(props) => (<WorkPreferences {...props} />)} /> */}
                <Route component={Page404} />
              </Switch>
            )}
          </MainPage>
        </FlexRowFlexColumn>
      </PageWrapper>
    </>
  );
};

export default withProfiles(Settings);
