import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const Wrapper = styled.div`
  min-height: 100vh;
  color: white;
  background-color: #111112;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent || 'flex-start'};

  @media ${pageSize.S} {
    background-image: ${props => `url('${props.backgroundImage}')`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    flex-direction: row;
    padding-bottom: inherit;
  }
`;
export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${props => `url('${props.backgroundImage}')`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  @media ${pageSize.S} {
    background-image: none;
  }

  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    flex: 1;
  }
  @media ${pageSize.S} {
    display: ${({ isFeatureBackgrounds }) => (isFeatureBackgrounds ? 'inherit' : 'none')};
    flex: 1 1 0%;
    padding: 0 3.5rem;
  }
  @media ${pageSize.XS} {
    width: 100%;
    min-height: 300px;
    padding: 0;
  }
`;
export const LogoImg = styled.img`
  width: 300px;
  height: 300px;
  margin: 0 auto;
  display: block;
  position: fixed;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  @media ${pageSize.M}, ${pageSize.S}, ${pageSize.XS} {
    position: unset;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0%;

  @media ${pageSize.XS} {
    background-color: #111112;

    & > div {
      padding: 36px 16px;
    }
    justify-content: flex-start;
    align-items: flex-start;
    flex: ${({ isFeatureBackgrounds }) => (isFeatureBackgrounds ? '1 1 0%' : '0')};
  }
  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    background-color: #111112;
    min-height: 100vh;
    overflow: scroll;

    & > div:first-child {
      height: unset;
    }

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
`;

export const ArtistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 16px;
  left: 16px;
  border-radius: 16px;
  background-color: #111112;
  gap: 16px;
  margin: 0 auto;

  & > img {
    width: 40px;
    height: 40px;
  }

  & > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
    padding: 16px 24px 16px 16px;
  }

  & > .divider {
    display: none;
    border-top: 2px solid rgba(204, 213, 255, 0.11);
  }

  @media ${pageSize.XS} {
    border-radius: unset;
    position: unset;
    bottom: unset;
    left: unset;
    padding: 16px;
    max-width: 440px;
    width: calc(100% - 32px);
    & > .divider {
      display: block;
    }

    & > .content {
      padding: 0 16px;
    }
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  max-width: 328px;
  padding: 3.5rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  border-radius: 1.5rem;

  & .hint {
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1rem;
    background: var(--White-100, #fff);
  }

  & a.learn-more {
    border-radius: 12px;
    border: 2px solid var(--white-white-35, rgba(229, 234, 255, 0.35));
    background: transparent;
    color: #fff;
    padding: 12px 20px;
    font-family: 'Space Grotesk';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
    letter-spacing: -0.16px;
    text-decoration: none;
  }

  & > .img-background {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
    margin-top: -3.5rem;
    max-width: 440px;
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }

  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  & > .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
  }

  @media ${pageSize.XS} {
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    padding: 1rem;
    min-height: 8.25rem;

    & > .content {
      width: 100%;
      gap: 1rem;
      align-items: flex-start;
    }
    & > .content > span {
      margin-top: 0.5rem;
    }

    & .title {
      font-size: 1.1875rem;
      line-height: 1.1875rem;
    }

    & > .img-background {
      max-width: 100%;
      width: 100%;
      margin: 0;
      border-radius: 0.5rem;
      height: unset;
    }
  }

  @media ${pageSize.S} {
    flex-direction: row;
    max-width: unset;
    padding: 1.5rem;
    min-height: 8.25rem;
    max-height: 180px;
    overflow: hidden;

    & > .content {
      gap: 1rem;
      align-items: flex-start;
    }

    & .title {
      font-size: 1.1875rem;
      line-height: normal;
      margin-bottom: -0.5rem;
    }

    & > .img-background {
      max-width: unset;
      margin: 0;
      margin: -1.5rem 0 -1.5rem -1.5rem;
      border-radius: 1.5rem 0 0 1.5rem;
      height: 11.25rem;
    }
  }

  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    display: flex;
    max-width: 328px;
    padding: 3.5rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 1.5rem;
    height: unset;

    & > .content {
      gap: 1rem;
      align-items: center;
    }

    & .title {
      font-size: 2rem;
      text-align: center;
    }

    & .description {
      margin: 0 40px 0 40px;
      text-align: center;
    }

    & > .img-background {
      margin: -3.5rem -1.5rem 0 -1.5rem;
      max-width: 440px;
      border-radius: 1.5rem 1.5rem 0 0;
      height: unset;
    }
  }
`;
