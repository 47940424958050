import React from 'react';
import { useHistory, useLocation } from 'react-router';
import ContainedButton from 'common/ContainedButton';
import { StyledAuthContainer, StyledHeaderDropdownItems } from './HeaderMobileStyles';
import HeaderMobileDropdownItem from './HeaderMobileDropdownItem';
import redirectToApp2 from 'utils/redirectToApp2';

const HeaderMobileUnauthorized = ({ onClick }) => {
  const location = useLocation();
  const history = useHistory();

  const handleLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.append('login', 'true');
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  return (
    <StyledHeaderDropdownItems onClick={onClick}>
      <HeaderMobileDropdownItem text="Help" icon="question" />
      <StyledAuthContainer>
        <ContainedButton onClick={handleLogin} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
          Sign in
        </ContainedButton>
        <ContainedButton
          onClick={() => redirectToApp2('/onboarding')}
          color="#111112"
          backgroundColor="#00FF00"
          forceDisplayText
        >
          Join
        </ContainedButton>
      </StyledAuthContainer>
    </StyledHeaderDropdownItems>
  );
};

export default HeaderMobileUnauthorized;
