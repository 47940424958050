import React from 'react';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import CompanyIcon from 'common/IconComponents/CompanyIcon';
import useApplicantService from 'hooks/api/useApplicantService';
import { pathOr } from 'ramda';

import { Avatar, IconBox, JobBy, MessageBox, ModalBox, TextBox } from './CompanyContractModal.styled';

const CompanyContractModal = ({ data }) => {
  const { hireApplicant } = useApplicantService();

  const companyPicture = pathOr('', ['activeProfile', 'basicInfo', 'imageUri', 'url'])(data);
  const companyName = pathOr('', ['activeProfile', 'name'])(data);
  const owner = pathOr({}, ['activeProfile', 'userOwner'])(data);

  const { applicant, jobId } = data;

  const createContract = async () => {
    await hireApplicant(applicant._id, jobId, response => {
      const url = `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/${response.contractId}/edit`;
      window.location.href = url;
    });
  };

  return (
    <TxplModal
      name={'company-contract-modal'}
      appElement={document.getElementById('root-modal')}
      title={'Company contract'}
      addPaddingTop
      allowLine={true}
      headerPaddingBottom={0}
      hideCloseIcon={false}
      size="small"
      renderFooter={
        <>
          <ContainedButton onClick={createContract}>Proceed to contract</ContainedButton>
        </>
      }
    >
      <ModalBox>
        <JobBy>
          This job is by <Avatar src={companyPicture} /> {companyName}
        </JobBy>
        <MessageBox>
          {' '}
          <IconBox>
            <CompanyIcon />
          </IconBox>
          <TextBox>
            As the account owner, you are creating a contract on behalf of {companyName}. Once contract is signed, its
            details and transactions will be included in your personal {owner?.firstName} {owner?.lastName} wallet.
          </TextBox>
        </MessageBox>
      </ModalBox>
    </TxplModal>
  );
};

export default withRenderPortal('company-contract-modal')(CompanyContractModal);
