import Api from './api';

const api = new Api('company-profile');

const getCompany = companyId => api.get(`/${companyId}`);
const saveCompanyService = params => api.post('/services', params);
const setTalentTags = (profileId, data) => api.post(`/${profileId}/tags`, data);
const getCompanyPreview = profileId => api.get(`/${profileId}/preview`);
const updateCompanyService = ({ _id, ...rest }) => api.put(`/services/${_id}`, rest);
const deleteCompanyService = (profileId, serviceId) => api.delete(`/${profileId}/${serviceId}`);
const setCompanyAboutText = (profileId, data) => api.post(`/${profileId}/about-text`, data);
const setAboutExperience = (profileId, data) => api.post(`/${profileId}/about-experience`, data);
const createCredit = (profileId, data) => api.post(`/${profileId}/credit`, data);
const updateCredit = (profileId, creditId, data) => api.put(`/${profileId}/credit/${creditId}`, data);
const updateProfile = (profileId, data) => api.put(`/${profileId}`, data);
const setCompanyProfileBasicInfo = (profileId, formData) =>
  api.post(`/${profileId}/basic-info`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      charset: 'utf-8'
    }
  });
const setCoverImage = (profileId, formData) =>
  api.post(`/${profileId}/cover-image`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });
const deleteCoverImage = profileId => api.delete(`/${profileId}/cover-image`);

const addRemoveFavorites = (talentId, data) => api.put(`/${talentId}/favorites`, data);

const getSavedSearches = (talentId, page, limit) =>
  api.get(`/${talentId}/saved-search/jobs?limit=${limit}&page=${page}`);

const deleteSavedSearch = (talentId, searchId) => api.delete(`/${talentId}/saved-search/jobs/${searchId}`);

const getRecentSearches = (talentId, page, limit) =>
  api.get(`/${talentId}/recent-search/jobs?limit=${limit}&page=${page}`);

const deleteRecentSearch = (talentId, searchId) => api.delete(`/${talentId}/recent-search/jobs/${searchId}`);

const getRecommendedJobs = (companyId, page, limit, sort) =>
  api.get(`/${companyId}/recommended-search/jobs?limit=${limit}&page=${page}&sort=${sort}`);

const updateCompanyServices = (companyId, data) => api.post(`/${companyId}/set-services`, { services: data });

const addMember = (companyId, data) => api.post(`/${companyId}/members`, data);
const editMember = (companyId, memberId, data) => api.put(`/${companyId}/members/${memberId}`, data);
const deleteMember = (companyId, memberId) => api.delete(`/${companyId}/members/${memberId}`);

export default {
  getCompany,
  saveCompanyService,
  updateCompanyService,
  deleteCompanyService,
  setCompanyAboutText,
  createCredit,
  setTalentTags,
  setAboutExperience,
  setCoverImage,
  deleteCoverImage,
  setCompanyProfileBasicInfo,
  updateCredit,
  getCompanyPreview,
  updateProfile,
  addRemoveFavorites,
  getSavedSearches,
  deleteSavedSearch,
  getRecentSearches,
  deleteRecentSearch,
  getRecommendedJobs,
  updateCompanyServices,
  addMember,
  editMember,
  deleteMember
};
