import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import Seo from 'common/Seo';
import ResetPassword from 'pages/ResetPassword';
import SplashSignInForm from './SplashSignInForm';
import ForgotPassword from './ForgotPassword';
import * as Styled from './StyledSplashPage';
import useViewport from 'hooks/useViewport';
import Typography from 'components/shared/Typography';
import { artistBackgrounds, featureBackgrounds } from './SplashPage.utils';
import { AnchorOutline } from './SplashPage.styled';

const useFeatureBackgrounds = process.env.REACT_APP_FF_USE_FEATURE_PROMO_LOGIN === 'true';

const backgrounds = [...(useFeatureBackgrounds ? featureBackgrounds : artistBackgrounds)];

const getRandomInt = max => Math.floor(Math.random() * max);

const SplashPage = () => {
  const location = useLocation();
  const { isXS } = useViewport();
  const [randomBackground, setRandomBackground] = useState(null);

  const splashForm = () => {
    const path = location.pathname;
    if (path.includes('login')) {
      return <SplashSignInForm />;
    }
    if (path.includes('forgot-password')) {
      return <ForgotPassword />;
    }
    if (path.includes('reset-password')) {
      return <ResetPassword />;
    }
    return <Redirect to="/login" />;
  };

  const wrapperProps = useFeatureBackgrounds
    ? { justifyContent: 'center ' }
    : {
        backgroundImage: randomBackground?.imageUrl,
        justifyContent: !isXS ? 'center' : 'unset'
      };

  const logoWrapperProps = useFeatureBackgrounds
    ? { style: randomBackground?.wrapperStyle }
    : {
        backgroundImage: randomBackground?.imageUrl
      };

  useEffect(() => {
    setRandomBackground(backgrounds[getRandomInt(backgrounds.length)]);
  }, []);

  // This is causing a bug, the page enters an infinite loop
  // useEffect(() => {
  //   const refreshToken = getCookie(`${cookieAuthName}.rt`);
  //   if (refreshToken) {
  //     history.push('/profile');
  //   }
  // }, []);

  return (
    <Styled.Wrapper {...wrapperProps}>
      <Seo />
      <Styled.LogoWrapper {...logoWrapperProps} isFeatureBackgrounds={useFeatureBackgrounds}>
        {!randomBackground?.title && !randomBackground?.artist && (
          <Styled.LogoImg
            src={`${process.env.PUBLIC_URL}/assets/splash-page/logo-animation-on-transparent.gif`}
            alt="Logo - Splash Page"
          />
        )}
        {useFeatureBackgrounds && !!randomBackground && (
          <Styled.FeatureWrapper style={randomBackground?.cardStyle}>
            <img className="img-background" src={randomBackground?.imageUrl} alt={randomBackground.title} />
            <div className="content">
              <div className="hint" style={randomBackground?.hintStyle}>
                <Typography color="currentColor" fontStyle="normal" fontSize="16px" lineHeight="16px" fontWeight="600">
                  {randomBackground?.hint}
                </Typography>
              </div>
              <Typography
                className="title"
                fontfamily="Space Grotesk"
                color="white"
                fontStyle="normal"
                fontSize="32px"
                lineHeight="35.2px"
                fontWeight="600"
              >
                {randomBackground?.title}
              </Typography>
              {randomBackground?.subTitle && (
                <Typography
                  className="subtitle"
                  fontfamily="Space Grotesk"
                  color="white"
                  fontStyle="normal"
                  fontSize="1.125rem"
                  lineHeight="110%"
                  fontWeight="400"
                >
                  {randomBackground.subTitle}
                </Typography>
              )}
              {randomBackground?.description && (
                <Typography className="description" color="white" fontStyle="normal" fontSize="16px" lineHeight="22px">
                  {randomBackground.description}
                </Typography>
              )}
              {randomBackground.action && randomBackground.actionUrl && (
                <AnchorOutline href={randomBackground.actionUrl}>{randomBackground.action}</AnchorOutline>
              )}
            </div>
          </Styled.FeatureWrapper>
        )}
      </Styled.LogoWrapper>
      <Styled.FormWrapper isFeatureBackgrounds={useFeatureBackgrounds}>{splashForm()}</Styled.FormWrapper>
      {randomBackground?.artist && (
        <Styled.ArtistWrapper>
          <div className="divider" />
          <div className="content">
            <img src={randomBackground.artistImageUrl} alt={randomBackground.artist} />
            <div>
              <Typography color="rgba(230, 234, 255, 0.35)" fontStyle="normal" fontSize="13px" lineHeight="16px">
                Artwork by
                <Typography color="white" fontStyle="normal" fontSize="19px" lineHeight="26px" bold>
                  {randomBackground.artist}
                </Typography>
              </Typography>
            </div>
          </div>
        </Styled.ArtistWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default SplashPage;
