import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import Typography from 'components/shared/Typography';
import FormSwitcher from 'common/form/FormSwitcher';
import { pageSize } from 'utils/devices';
import ContainedButton from 'common/ContainedButton';
import useViewport from 'hooks/useViewport';
import DisplaySwitcher from 'common/DisplaySwitcher';
import useJobServices from 'hooks/api/useJobServices';
import { profileSelectors } from 'store/profile';
import { useSelector } from 'react-redux';
import { jobsSelectors } from 'store/jobs';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  background: rgba(17, 17, 18, 1);
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 16px;
  padding: 24px;
  @media ${pageSize.XS}, ${pageSize.S}, ${pageSize.M} {
    flex-flow: column;
  }
  @media ${pageSize.XS} {
    padding: 16px;
  }
`;
const StyledFormSwitcher = styled(FormSwitcher)`
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  display: flex;
  padding: 2px;
  button.switcher-button {
    padding: 8px 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 6px;
  @media ${pageSize.XS} {
    gap: 16px;
    flex-grow: 1;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 6px;
  @media ${pageSize.XS} {
    gap: 16px;
    flex-grow: 1;
  }
  @media ${pageSize.S}, ${pageSize.M} {
    justify-content: space-between;
  }
`;

const postedStatusFields = [
  {
    name: 'Unstaffed',
    value: 'unstaffed'
  },
  {
    name: 'Staffed',
    value: 'staffed'
  },
  {
    name: 'Closed',
    value: 'closed'
  }
];

const FilterButton = styled(ContainedButton)`
  button.filter-btn {
    height: 100%;
  }
`;

const Flex = styled.div`
  display: flex;
  overflow: auto;
`;

const JobsHeader = ({ header, recommendedUrl, setStatus, status, ...rest }) => {
  const { isXS } = useViewport();
  const forAllJobs = header === 'all';
  const forFavoriteJobs = header === 'favorite';
  const forJobsIPosted = header === 'posted-jobs';
  const { getUnreadJobsCounters } = useJobServices();
  const profile = useSelector(profileSelectors.selectActiveProfileId);
  const counters = useSelector(jobsSelectors.selectUnreadCounters);

  const myJobsOptions = useMemo(
    () => [
      {
        name: 'Saved',
        value: 'saved',
        indicator: counters?.savedCount === 0 ? null : counters?.savedCount
      },
      {
        name: 'Applied',
        value: 'applied',
        indicator: counters?.appliedCount === 0 ? null : counters?.appliedCount
      },
      {
        name: 'Offers', // ONLY affects the label in FE tab. The value is still 'selected'
        value: 'selected',
        indicator: counters?.selectedCount === 0 ? null : counters?.selectedCount
      },
      {
        name: 'Hired',
        value: 'hired',
        indicator: counters?.hiredCount === 0 ? null : counters?.hiredCount
      }
    ],
    [counters]
  );

  useEffect(() => {
    getUnreadJobsCounters(profile);
  }, []);

  return (
    <Header {...rest}>
      {(forFavoriteJobs || forJobsIPosted) && (
        <Flex>
          <StyledFormSwitcher
            backgroundColor="transparent"
            initialValue={status}
            options={forFavoriteJobs ? myJobsOptions : postedStatusFields}
            onChange={setStatus}
          />
        </Flex>
      )}
      {forAllJobs && (
        <div>
          <Typography variant="h3">Recommended for you</Typography>
          <Typography variant="small" color="rgba(230, 234, 255, 0.35)">
            Based on your profile and search history
          </Typography>
        </div>
      )}

      <ButtonContainer>
        {!isXS && forAllJobs && (
          <FilterButton
            className="filter-btn"
            icon="filter"
            backgroundColor="rgba(204, 213, 255, 0.11)"
            onClick={() => (window.location.href = recommendedUrl)}
          >
            Advanced Search
          </FilterButton>
        )}

        <ButtonWrapper>{!isXS && <DisplaySwitcher />}</ButtonWrapper>
      </ButtonContainer>
    </Header>
  );
};

export default JobsHeader;
