import styled from '@emotion/styled';
import LimitedTypography from 'components/shared/LimitedTypography';
import Tooltip from 'components/Tooltip/Tooltip';
import useOutsideClick from 'hooks/useOutsideClick';
import useProfilePath from 'hooks/useProfilePath';
import useProfileSwitcher from 'hooks/useProfileSwitcher';
import BackofficeUserMenuItem from 'pages/backoffice/BackofficeUserMenuItem';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { profileSelectors } from 'store/profile';
import { portalActions } from 'store/portals';
import { selectMyProfile, selectProfileProperty } from 'store/profile/profileSelectors';
import { DEFAULT_AVATAR } from 'utils/assets';
import HeaderContainer from './HeaderContainer';
import LoginAsMode from './LoginAsMode';
import Flex from './styles/Flex';
import { cookieAuthAsName, getCookie, getDecodedIdToken } from 'utils/cookies';
import { authService } from 'api';
import { authActions } from 'store/auth';
import { get } from 'lodash/fp';
import { apiCallActions } from 'store/apiCall';

const Avatar = styled.img`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;

  border: 4px solid #111112;
  &:hover {
    border: 4px solid rgba(189, 200, 255, 0.05);
  }
  &.opened {
    cursor: pointer;
    border: 4px solid rgba(191, 202, 255, 0.1);
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 398px;
`;

const ItemsWrapper = styled.div`
  position: absolute;
  top: 35px;
  box-sizing: border-box;
  background-color: #1a1b1e;
  border: 2px solid rgba(204, 213, 255, 0.11);
  text-transform: initial;
  border-radius: 8px;

  margin-top: 30px;
  z-index: 100;
  padding: 8px;
  ${props => (props.isOpen ? '' : 'display: none;')}
`;

const SideWrapper = styled.div`
  position: absolute;
  top: 160px;
  right: 100%;
  background-color: #1a1b1e;
  padding: 8px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
`;

const LowerWrapper = styled.div`
  border-top: 2px solid rgba(204, 213, 255, 0.11);
  padding-top: 8px;
`;

const UpperWrapper = styled.div`
  padding-bottom: 8px;
`;

const UserProfile = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  .headerIcon path {
    fill: rgba(230, 234, 255, 0.6);
  }
  &:hover {
    cursor: pointer;
    .headerUser {
      color: #fff;
    }
    .headerIcon path {
      fill: #fff;
    }
  }
  &.opened {
    cursor: pointer;
    .headerUser {
      color: #fff;
    }
    .headerIcon path {
      fill: #fff;
    }
  }
  position: relative;
  &.opened:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -23px;
    width: 100%;
    height: 2px;
  }
`;
const StyledMenuItem = styled(BackofficeUserMenuItem)`
  border-top: none;
  border-radius: 8px;
  ${props => (props.isSubMenuOpen ? 'background: rgba(204, 213, 255, 0.04);' : '')}
`;

const StyledTooltip = styled(Tooltip)`
  .__react_component_tooltip.show {
    border: 1px solid rgba(204, 213, 255, 0.22) !important;
  }
`;

const PageHeaderUser = ({ searchValue, setSearchValue }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
  const [accountMenuIsOpen, setAccountMenuIsOpen] = useState(false);
  const [helpMenuIsOpen, setHelpMenuIsOpen] = useState(false);
  const history = useHistory();
  const name = useSelector(selectProfileProperty('name'));
  const activeProfile = useSelector(selectMyProfile);
  const { switchProfile } = useProfileSwitcher();
  const { getProfilePath, myProfilePath } = useProfilePath();
  const isProfileLoading = useSelector(profileSelectors.selectIsProfileLoading);

  const decoded = getDecodedIdToken();
  const onBehalfOf = getCookie(cookieAuthAsName);

  const currentUserIsSuperUser = decoded?.roles.includes('super-admin');
  const currentUserIsAnalyst = decoded?.roles.includes('analyst');
  const profiles = useSelector(profileSelectors.selectMyProfileList);

  const shouldShowBackOffice = currentUserIsAnalyst || currentUserIsSuperUser;

  const [showSearch, setShowSearch] = useState(false);
  const closeSideMenus = () => {
    setAccountMenuIsOpen(false);
    setHelpMenuIsOpen(false);
  };

  useOutsideClick(ref, () => {
    if (userMenuIsOpen) {
      closeSideMenus();
      setUserMenuIsOpen(false);
    }
  });

  const userProfilePic = activeProfile?.basicInfo?.imageUri?.url;

  const helpPopup = () => {
    dispatch(
      portalActions.openPortal({
        name: 'help-modal'
      })
    );
  };

  return (
    <FlexColumn>
      <Flex>
        <FlexRow>
          <HeaderContainer
            userMenuIsOpen={userMenuIsOpen}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
          />
          <UserProfile
            ref={ref}
            onClick={() => setUserMenuIsOpen(!userMenuIsOpen)}
            className={`${userMenuIsOpen ? 'opened' : ''}`}
          >
            <LoginAsMode />
            <Avatar
              className={`${userMenuIsOpen ? 'opened' : ''}`}
              src={userProfilePic || DEFAULT_AVATAR}
              alt=""
              data-testid="avatar-header"
            />
          </UserProfile>
        </FlexRow>
      </Flex>

      <ItemsWrapper isOpen={userMenuIsOpen}>
        <UpperWrapper>
          <StyledMenuItem
            className="pageHeaderUserInfo"
            icon="profile"
            text="View Profile"
            url={isProfileLoading ? '/profile' : `${myProfilePath}/about`}
            onMouseEnter={() => closeSideMenus()}
            onClick={() => setUserMenuIsOpen(!userMenuIsOpen)}
            data-testid="view-profile"
          />
          <StyledMenuItem
            className="pageHeaderUserInfo"
            icon="settings"
            text="Settings"
            url="/settings"
            onMouseEnter={() => closeSideMenus()}
            onClick={() => setUserMenuIsOpen(!userMenuIsOpen)}
            data-testid="settings"
          />
          {helpMenuIsOpen && (
            <SideWrapper style={{ top: 100 }}>
              <StyledMenuItem
                icon={'question'}
                textWidth={'148px'}
                text={<LimitedTypography text={'Read our FAQs'} tooltip={false} />}
                onClick={() =>
                  window.open('https://intercom.help/thexplace-faqs/en/collections/8854453-miscellaneous ')
                }
                data-testid={`help`}
              />
              <StyledMenuItem
                icon={'message'}
                textWidth={'148px'}
                text={<LimitedTypography text={'Contact support'} tooltip={false} />}
                onClick={() => helpPopup()}
                data-testid={`help`}
              />
            </SideWrapper>
          )}
          <StyledMenuItem
            className="pageHeaderUserInfo"
            icon="question"
            text="Help"
            secondaryIcon="arrow-right"
            onMouseEnter={() => {
              setHelpMenuIsOpen(true);
              setAccountMenuIsOpen(false);
            }}
            onClick={() => setUserMenuIsOpen(true)}
            data-testid="help"
          />
        </UpperWrapper>

        <LowerWrapper>
          {profiles.length > 1 && (
            <>
              {accountMenuIsOpen && (
                <SideWrapper>
                  {profiles.map((profile, index) => (
                    <StyledMenuItem
                      key={profile._id}
                      avatar={profile.avatar || DEFAULT_AVATAR}
                      textWidth={'148px'}
                      text={<LimitedTypography text={profile.name} tooltip={false} />}
                      profileId={profile._id}
                      onClick={() =>
                        switchProfile(profile, () =>
                          history.push(getProfilePath(profile.publicUrl, profile.type === 'company'))
                        )
                      }
                      data-testid={`switch-account-item-${index}`}
                    />
                  ))}
                </SideWrapper>
              )}
              <StyledMenuItem
                isSubMenuOpen={accountMenuIsOpen}
                className="pageHeaderUserInfo"
                icon="arrow-switch"
                text="Switch accounts"
                secondaryIcon="arrow-right"
                onMouseEnter={() => {
                  setAccountMenuIsOpen(true);
                  setHelpMenuIsOpen(false);
                }}
                onClick={() => setUserMenuIsOpen(true)}
                data-testid="switch-accounts"
              />
            </>
          )}
          {shouldShowBackOffice && (
            <StyledMenuItem
              className="pageHeaderUserInfo"
              icon="backoffice"
              text="Back office"
              onClick={() => {
                if (onBehalfOf) {
                  authService
                    .autoLogin({ removeOnBehalfOf: true })
                    .then(response => {
                      const user = get('data.user', response);

                      if (user) {
                        dispatch(authActions.login(user));
                      }
                      history.push('/backoffice');
                    })
                    .catch(() => {
                      history.push('/login');
                    })
                    .finally(() => {
                      dispatch(apiCallActions.sendRequestSuccess({ name: 'currentUser' }));
                    });
                } else {
                  history.push('/backoffice');
                }
              }}
              onMouseEnter={() => closeSideMenus()}
              data-testid="backoffice"
            />
          )}
          <StyledMenuItem
            className="pageHeaderUserInfo"
            icon="logout"
            text="Log out"
            url="/logout"
            onMouseEnter={() => closeSideMenus()}
            data-testid="logout"
          />
        </LowerWrapper>
      </ItemsWrapper>
      <StyledTooltip tooltipId="menuUserName" place="right">
        {name}
      </StyledTooltip>
    </FlexColumn>
  );
};

export default PageHeaderUser;
