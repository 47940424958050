export const featureBackgrounds = [
  {
    hint: 'What’s new?',
    title: 'Contracting and payments',
    subTitle: '',
    description: 'Create contracts, get paid, and pay talent — seamlessly',
    imageUrl: 'https://production-thexplace-static-content.s3.us-west-1.amazonaws.com/images/contracts-payments.gif',
    action: 'Learn more',
    actionUrl: null,
    wrapperStyle: {
      background:
        'radial-gradient(105.73% 86.43% at 123.12% 2.11%, rgba(74, 0, 196, 0.68) 0%, rgba(176, 0, 220, 0.24) 59%, rgba(255, 0, 107, 0.00) 100%), radial-gradient(66.33% 82.92% at 0% 100%, rgba(74, 0, 196, 0.68) 0%, rgba(176, 0, 220, 0.24) 59%, rgba(255, 0, 107, 0.00) 100%)'
    },
    cardStyle: {
      background: 'radial-gradient(186.57% 124.38% at 0% 100%, #A500CE 0%, #C1255B 100%)'
    },
    hintStyle: {
      color: 'rgba(181, 21, 139, 1)'
    }
  },
  {
    hint: 'Did you know?',
    title: 'You can share your credits',
    subTitle: 'With who? 🤔',
    description: null,
    imageUrl: 'https://production-thexplace-static-content.s3.us-west-1.amazonaws.com/images/credits-claimed.png',
    action: 'Learn how',
    actionUrl: null,
    wrapperStyle: {
      background:
        'radial-gradient(71.02% 86.28% at 140.35% 36.39%, rgba(25, 52, 194, 0.70) 0%, rgba(25, 52, 194, 0.30) 72.4%, rgba(25, 52, 194, 0.00) 100%), radial-gradient(87.39% 109.24% at 0% 100%, rgba(25, 52, 194, 0.70) 0%, rgba(25, 52, 194, 0.30) 72.4%, rgba(25, 52, 194, 0.00) 100%)'
    },
    cardStyle: {
      background: 'radial-gradient(108.67% 107.63% at 0% 100%, #1970C2 0%, #1495FF 100%)'
    },
    hintStyle: {
      color: 'rgba(21, 144, 247, 1)'
    }
  }
];

export const artistBackgrounds = [
  {
    imageUrl: `${process.env.PUBLIC_URL}/assets/splash-page/santi-arderius.jpg`,
    artist: 'Santi Arderíus (DSNZAN)',
    artistImageUrl: `${process.env.PUBLIC_URL}/assets/splash-page/santi-arderius-avatar.png`
  },
  {
    imageUrl: `${process.env.PUBLIC_URL}/assets/splash-page/costa-lazar.jpg`,
    artist: 'Costa Lazar',
    artistImageUrl: `${process.env.PUBLIC_URL}/assets/splash-page/costa-lazar-avatar.png`
  },
  {
    imageUrl: `${process.env.PUBLIC_URL}/assets/splash-page/stevan-stanisic.jpg`,
    artist: 'Stevan Stanišić (Sted)',
    artistImageUrl: `${process.env.PUBLIC_URL}/assets/splash-page/stevan-stanisic-avatar.png`
  }
];
