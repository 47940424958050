import styled from '@emotion/styled';
import useOutsideClick from 'hooks/useOutsideClick';
import useProfilePath from 'hooks/useProfilePath';
import useViewport from 'hooks/useViewport';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import notificationSelectors from 'store/notifications/notificationSelectors';
import { pageSize } from 'utils/devices';
import Badge from '../components/Badge';
import Notifications from '../components/Notifications';
import { profileSelectors } from '../store/profile';
import FormSearch from './form/FormSearch';
import Icon from './Icon';
import { currentUserHasFFSelector } from 'store/selectors/currentUser';
const HeaderMenuItem = styled.div`
  cursor: pointer;
  font-family: Space Grotesk;
  text-align: center;
  color: rgba(230, 234, 255, 0.6);
  width: 100%;
  position: relative;
  border-bottom: 2px solid transparent;
  position: relative;
  .header-Icon path {
    fill: rgba(230, 234, 255, 0.35) !important;
  }
  &:hover,
  &.opened {
    .header-MenuLabel {
      color: white;
    }
    .header-Icon path {
      fill: white !important;
    }
  }
  &.disabled {
    cursor: default;
    .header-Icon path {
      fill: rgba(230, 234, 255, 0.35);
    }
    .header-MenuLabel {
      color: rgba(230, 234, 255, 0.35);
    }
    &:hover,
    &.opened {
      .header-Icon path {
        fill: rgba(230, 234, 255, 0.35) !important;
      }
    }
  }
  ${({ active }) =>
    active &&
    `
    .header-MenuLabel {
      color: white;
    }
  `}
`;
const HeaderMenuIcon = styled.div`
  text-transform: capitalize;

  border-radius: 8px;
  width: 40px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active svg path {
    fill: white !important;
  }

  &:hover,
  &.active {
    cursor: pointer;
    background-color: #bfcaff1a;
  }
`;
const Header = styled.div`
  display: flex;
  gap: 0.25rem;
  margin-right: 14px;
  align-items: center;
  justify-content: center;
`;
const HeaderMenuLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: rgba(230, 234, 255, 0.6);
  text-transform: capitalize;
  padding-top: 6px;
  letter-spacing: 0.04em;
  @media ${pageSize.L}, ${pageSize.M}, ${pageSize.S}, ${pageSize.XS} {
    display: none;
  }
`;
const HeaderMenuBadge = styled.div`
  position: absolute;
  top: -12px;
  right: 1%;
`;

const ItemsWrapper = styled.div`
  min-height: 300px;
  max-height: 600px;
  width: 432px;
  position: absolute;
  bottom: -2px;
  left: -180px;
  transform: translate(-35%, 100%);
  box-sizing: border-box;
  background-color: #1a1b1e;
  border: 1px solid rgba(191, 202, 255, 0.1);
  border-radius: 16px;
  overflow: hidden;
  z-index: 100;
  ${props => (props.isOpen ? '' : 'display: none;')}
  @media ${pageSize.M} {
    left: -150px;
  }
  @media ${pageSize.XS} {
    max-width: 100%;
    max-height: 100%;
  }
`;

const FormWrapper = styled.div`
  margin-right: 45px;
`;

const HeaderContainer = ({ searchValue, setSearchValue, setShowSearch, showSearch }) => {
  const ref = useRef();
  const history = useHistory();
  const location = useLocation();
  const { isS } = useViewport();
  const { myProfilePath } = useProfilePath();

  const [updateMenuIsOpen, setUpdateMenuIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState();

  const unseenNotificationsCount = useSelector(notificationSelectors.selectAllUnreadNotificationsCount);
  const isCompanyView = useSelector(profileSelectors.selectIsMyProfileCompany);
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const conversations = useSelector(profileSelectors.selectProfileProperty('conversations'));
  const { pathname } = location;

  const closeNotifications = e => {
    e && e.stopPropagation();
    setActiveTab(null);
    setUpdateMenuIsOpen(false);
  };

  useOutsideClick(ref, () => {
    if (updateMenuIsOpen) {
      closeNotifications();
    }
  });

  const handleEventsClick = () => {
    window.location.assign(`${process.env.REACT_APP_NEXT_BASE_URL}/events`);
  };

  const handleContractsClick = () => {
    window.location.assign(`${process.env.REACT_APP_NEXT_BASE_URL}/contracts`);
  };

  const handleJobsClick = () => {
    history.push(`${myProfilePath}/all-jobs`);
  };

  const handleMessagesClick = () => {
    const title = isCompanyView ? 'company' : 'talent';
    history.push(`/${title}/${profileId}/conversations`);
  };

  const isCommunityPath = !!pathname.includes('community');
  const isAllJobs = pathname.includes('all-jobs');
  const isMyJobs = pathname.includes('my-jobs');
  const isPostedJobs = pathname.includes('jobs-i-posted');
  const isJobPath = isAllJobs || isMyJobs || isPostedJobs;

  const unreadConversations =
    conversations && Object.values(conversations).filter(convrsation => convrsation.custom?.unreadMessages > 0);

  const userHasWalletEnabled = useSelector(currentUserHasFFSelector('hasWalletEnabled'));
  const displayWallet = userHasWalletEnabled && !isCompanyView;
  const userHasContractEnabled = useSelector(currentUserHasFFSelector('hasContractEnabled'));
  const displayContracts = userHasContractEnabled && !isCompanyView;

  return (
    <Header>
      {isS && (
        <>
          <FormWrapper>
            <FormSearch
              style={{ width: '260px' }}
              value={searchValue}
              icon="search"
              onChange={e => {
                setSearchValue(e.target.value);
              }}
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              iconTop={showSearch ? '22' : '-1'}
              searchIconColor={!showSearch && 'rgba(230, 234, 255, 0.35)'}
              data-testid="search-header"
            />
          </FormWrapper>
          {!showSearch && (
            <>
              <HeaderMenuItem
                onClick={handleJobsClick}
                active={activeTab === 2 || isJobPath}
                data-testid="jobs-icon-header"
              >
                <HeaderMenuIcon className={`${isJobPath && 'active'}`}>
                  <Icon className="header-Icon" icon="jobs" size={16} />
                </HeaderMenuIcon>
                <HeaderMenuLabel className="header-MenuLabel">Jobs</HeaderMenuLabel>
              </HeaderMenuItem>
              {displayContracts && (
                <HeaderMenuItem
                  active={activeTab === 5}
                  onClick={handleContractsClick}
                  data-testid="contracts-icon-header"
                >
                  <HeaderMenuIcon data-tip data-for="contracts">
                    <Icon className="header-Icon" icon="crossed-hammer" size={16} />
                  </HeaderMenuIcon>
                  <HeaderMenuLabel className="header-MenuLabel">Contracts</HeaderMenuLabel>
                </HeaderMenuItem>
              )}
              <HeaderMenuItem onClick={handleEventsClick} data-testid="events-icon-header">
                <HeaderMenuIcon>
                  <Icon className="header-Icon" icon="events" size={16} />
                </HeaderMenuIcon>
                <HeaderMenuLabel className="header-MenuLabel">Events</HeaderMenuLabel>
              </HeaderMenuItem>
            </>
          )}
        </>
      )}
      {!showSearch && (
        <>
          {displayWallet && (
            <HeaderMenuItem data-testid="wallet-icon-header" data-tip data-for="wallet">
              <a href={`${process.env.REACT_APP_NEXT_BASE_URL}/wallet`}>
                <HeaderMenuIcon>
                  <Icon className="header-Icon" icon="wallet" size={16} />
                </HeaderMenuIcon>
                <HeaderMenuLabel className="header-MenuLabel">Wallet</HeaderMenuLabel>
              </a>
            </HeaderMenuItem>
          )}
          <HeaderMenuItem
            onClick={handleMessagesClick}
            active={activeTab === 3 || pathname.includes('conversations')}
            data-testid="messages-icon-header"
          >
            <HeaderMenuIcon className={`${pathname.includes('conversations') && 'active'}`}>
              <Icon className="header-Icon" icon="message" size={16} />
            </HeaderMenuIcon>
            <HeaderMenuLabel className="header-MenuLabel">Messages</HeaderMenuLabel>
            {unreadConversations?.length > 0 && (
              <HeaderMenuBadge>
                <Badge text={unreadConversations?.length} color="#FF5151" textColor="#fff" borderRadius={8} />
              </HeaderMenuBadge>
            )}
          </HeaderMenuItem>
          <HeaderMenuItem
            onClick={() => setActiveTab(4)}
            active={activeTab === 4}
            className={`${updateMenuIsOpen ? 'opened' : ''}`}
            data-testid="updates-icon-header"
          >
            <div onClick={() => setUpdateMenuIsOpen(!updateMenuIsOpen)}>
              <HeaderMenuIcon className={updateMenuIsOpen ? 'active' : ''}>
                <Icon className="header-Icon" icon="notification_ring" size={16} />
              </HeaderMenuIcon>
              <HeaderMenuLabel className="header-MenuLabel">Updates</HeaderMenuLabel>
              {unseenNotificationsCount > 0 && (
                <HeaderMenuBadge>
                  <Badge text={unseenNotificationsCount} color="#5448DB" textColor="#fff" borderRadius={8} />
                </HeaderMenuBadge>
              )}
            </div>

            <ItemsWrapper ref={ref} isOpen={updateMenuIsOpen}>
              <Notifications updateMenuIsOpen={updateMenuIsOpen} closeNotifications={closeNotifications} />
            </ItemsWrapper>
          </HeaderMenuItem>
        </>
      )}
    </Header>
  );
};

export default HeaderContainer;
