import Api from './api';

const api = new Api('jobs');

const getImages = talentId => api.get(`/${talentId}/images`);

const getJob = (talentId, jobId) => api.get(`/${talentId}/${jobId}`);

const getMyJobs = (profileId, status, skip, limit) =>
  api({
    url: `/${profileId}/posted`,
    method: 'GET',
    params: {
      skip,
      limit,
      status
    }
  });

const getJobsFromService = (page, limit, filters) =>
  api({
    url: `?${filters}`,
    method: 'GET',
    params: {
      page,
      limit
    }
  });

const updatePostedJobsOrder = (profileId, data) =>
  api({
    url: `/${profileId}/posted/order`,
    method: 'PUT',
    data
  });

const getApplicants = (jobId, filters, page, limit) =>
  api({
    url: `/${jobId}/relevant-applicants?${filters}`,
    method: 'GET',
    params: {
      page,
      limit
    }
  });

const hireApplicant = (profileId, jobId) => api.post(`${profileId}/${jobId}/hire`);
const createProposal = profileId => api.post(`${profileId}/create-proposal`);

const notifyOwnerContractCreation = (profileId, jobId, data) => jobId ?
  api.post(`${profileId}/${jobId}/notify-owner-contract-creation`, data) : api.post(`${profileId}/notify-owner-contract-creation`, data)  ;

const rejectApplicant = (profileId, jobId) => api.post(`${profileId}/${jobId}/reject`);

const changeApplicantStatus = (profileId, jobId, status) =>
  api({
    url: `${profileId}/${jobId}/relevant-applicants/status`,
    method: 'POST',
    data: {
      status
    }
  });

const commentOnApplicant = (creatorId, talentId, img, name, jobId, text) =>
  api({
    url: `${creatorId}/${talentId}/${jobId}/comment`,
    method: 'POST',
    data: {
      text,
      img,
      name
    }
  });

const editComment = (jobId, commentId, text) =>
  api({
    url: `${jobId}/comment/${commentId}`,
    method: 'POST',
    data: {
      text
    }
  });

const deleteComment = (jobId, commentId) => api.delete(`${jobId}/comment/${commentId}`);

const deleteContract = (contractId, jobId, applicantId) =>
  api({
    url: `${jobId}/contract/${contractId}`,
    method: 'DELETE',
    data: {
      talentId: applicantId
    }
  });

const cancelContract = (contractId, description) => api.post(`/contract/cancel/${contractId}`, { description });

const getSavedJobs = (talentId, skip, limit) => api.get(`/${talentId}/favorites?skip=${skip}&limit=${limit}`);

const getAppliedJobs = (talentId, skip, limit) => api.get(`/${talentId}/applied?skip=${skip}&limit=${limit}`);

const getSelectedJobs = (talentId, skip, limit) =>
  api.get(`/${talentId}/contracted?skip=${skip}&limit=${limit}&status=selected`);

const getHiredJobs = (talentId, skip, limit) =>
  api.get(`/${talentId}/contracted?skip=${skip}&limit=${limit}&status=hired`);

const getUnreadJobsCounters = talentId => api.get(`/${talentId}/counters`);

const addJob = (talentId, data) => api.post(`/${talentId}`, data);

const updateJob = (talentId, jobId, data) => api.post(`/${talentId}/${jobId}`, data);

const saveContacted = (profileId, jobId) => api.post(`${profileId}/${jobId}/contacted`);

const changeJobStatus = (talentId, jobId, status) =>
  api({
    url: `/${talentId}/${jobId}/status`,
    method: 'POST',
    data: {
      status
    }
  });

const search = (profileId, query, page, sort, limit, isCountSearch) =>
  api({
    url: `/${profileId}/search?${query}`,
    method: 'GET',
    params: {
      page,
      sort,
      limit,
      isCountSearch
    }
  });

const saveJobSearch = (talentId, data) => api.post(`/${talentId}/search/save`, data);

export default {
  getImages,
  getJob,
  getJobsFromService,
  getMyJobs,
  updatePostedJobsOrder,
  getApplicants,
  getSavedJobs,
  getAppliedJobs,
  getSelectedJobs,
  getHiredJobs,
  getUnreadJobsCounters,
  hireApplicant,
  createProposal,
  rejectApplicant,
  changeApplicantStatus,
  commentOnApplicant,
  editComment,
  deleteComment,
  addJob,
  updateJob,
  changeJobStatus,
  search,
  saveJobSearch,
  deleteContract,
  cancelContract,
  saveContacted,
  notifyOwnerContractCreation
};
