import React, { useState } from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import useApplicantService from 'hooks/api/useApplicantService';

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;
const StyledChosenCandidateName = styled.span`
  color: white;
`;

const ApproveCandidateModal = ({ closePortal, data, name }) => {
  const { hireApplicant } = useApplicantService();
  const { chosenCandidate, jobId, setChosenCandidate } = data;
  const [loading, setLoading] = useState(false);

  const handleHireApplicant = () => {
    setLoading(true);
    hireApplicant(chosenCandidate.id, jobId).then(() => {
      setChosenCandidate(false);
      setLoading(false);
      closePortal();
    });
  };

  return (
    <TxplModal
      title="Approve"
      name={name}
      appElement={document.getElementById('root')}
      size="small"
      padding="40px 0 56px"
      XSpadding="0 0 24px"
      nonFullScreenOnMobile
      hideBorder
      noPaddingTop
      renderFooter={
        <StyledButtonsContainer>
          {loading && <Spinner />}
          <ContainedButton backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton icon="thumbs-up" backgroundColor="#7266EE" onClick={handleHireApplicant}>
            Approve
          </ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <Typography color="rgba(230, 234, 255, 0.6)" fontSize="17px" lineHeight="28px" variant="medium">
        Are you sure you want to approve <StyledChosenCandidateName>{chosenCandidate.name}</StyledChosenCandidateName>{' '}
        for this job?
      </Typography>
    </TxplModal>
  );
};

export default withRenderPortal('approve-candidate-modal')(ApproveCandidateModal);
